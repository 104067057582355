import Ajv from 'ajv';
import { z } from 'zod';
import { AccountConst } from '@axiom/const';
import { SchemaTimestamp } from '@axiom/types';

import { axiomValidationOptions } from './options';
import { BaseUserSchema } from './base-user';
import { AccountContactSchema } from './contacts';

export const PreferredVideoValues = Object.values(
  AccountConst.PreferredVideoClient
) as NonEmptyArray<string>;

export const AccountSchema = z.object({
  id: z.string().uuid(),
  businessTeam: z.string().nullable(),
  calculatedConfidentialName: z.string().nullable(),
  calculatedName: z.string().nullable(),
  confidentialAccountName: z.string().nullable(),
  contacts: z.array(AccountContactSchema),
  createdAt: SchemaTimestamp,
  cxPartner: BaseUserSchema.nullish(),
  cxPartnerId: z.string().uuid().nullable(),
  deletedAt: SchemaTimestamp,
  displayName: z.string().max(255).nullable(),
  industryValue: z.string().nullable(),
  isAccountConfidential: z.boolean().default(false),
  name: z.string().max(255).nullable(),
  ownerUser: BaseUserSchema.nullish(),
  ownerUserId: z.string().uuid().nullable(),
  parentAccountId: z.string().uuid().nullable(),
  preferredVideoClient: z.enum(PreferredVideoValues).nullable(),
  salesforceId: z.string(),
  subIndustryId: z.string().uuid().nullable(),
  updatedAt: SchemaTimestamp,
  website: z.string().nullable(),
  zoomIndustry: z.string(),
  zoomRecordId: z.string(),
});

const ajv = new Ajv({
  ...axiomValidationOptions(),
});

const accountCommonValidation = {
  additionalProperties: false,
  type: 'object',
  properties: {
    name: {
      type: ['string', 'null'],
      minLength: 1,
      maxLength: 255,
    },
    displayName: {
      type: ['string', 'null'],
      minLength: 1,
      maxLength: 255,
    },
    ownerUserId: {
      type: ['string', 'null'],
    },
    businessTeam: {
      type: ['string', 'null'],
      minLength: 1,
      maxLength: 255,
    },
    cxPartnerId: {
      type: ['string', 'null'],
    },
    parentAccountId: {
      type: ['string', 'null'],
    },
    salesforceId: {
      type: ['string', 'null'],
      minLength: 1,
      maxLength: 255,
    },
    zoomIndustry: {
      type: ['string', 'null'],
      minLength: 1,
      maxLength: 255,
    },
    zoomRecordId: {
      type: ['string', 'null'],
      minLength: 1,
      maxLength: 255,
    },
    axiomSubIndustry: {
      type: ['string', 'null'],
    },
    industryValue: {
      type: ['string', 'null'],
    },
    subIndustryId: {
      type: ['string', 'null'],
      format: 'uuid',
    },
    isAccountConfidential: {
      type: ['boolean', 'null'],
    },
    confidentialAccountName: {
      type: ['string', 'null'],
    },
    website: {
      type: ['string', 'null'],
      maxLength: 255,
    },
  },
  dependencies: {
    isAccountConfidential: ['confidentialAccountName'],
  },
};

const accountsQueryValidation = {
  additionalProperties: false,
  type: 'object',
  properties: {
    search: { type: 'string' },
    page: { type: ['integer', 'string'], default: 1 },
    sort: { type: 'string', default: 'name' },
    filters: {
      type: 'object',
      additionalProperties: false,
      properties: {
        salesforceId: {
          type: 'array',
          items: {
            type: 'string',
            format: 'uuid',
          },
        },
        ownerUserId: {
          type: 'string',
          format: 'uuid',
        },
        contacts: {
          type: 'array',
          items: {
            type: 'string',
            format: 'uuid',
          },
        },
      },
    },
  },
};

export const createSalesForceAccountValidator = ajv.compile(
  accountCommonValidation
);

export const updateSalesforceAccountValidatorCreator = ({
  ajvInstance = ajv,
  additionalOpts = {},
} = {}) =>
  ajvInstance.compile({
    ...accountCommonValidation,
    ...additionalOpts,
    anyOf: Object.keys(accountCommonValidation.properties).map(key => ({
      required: [key],
    })),
  });

export const updateSalesforceAccountValidator =
  updateSalesforceAccountValidatorCreator();

// ONLY CONDITIONALLY USE THIS - for boomi endpoints only
export const updateSalesforceAccountAdditionalPropertiesValidator =
  updateSalesforceAccountValidatorCreator({
    ajvInstance: new Ajv({
      ...axiomValidationOptions(),
      removeAdditional: true,
    }),
    additionalOpts: {
      additionalProperties: false,
    },
  });

export const accountsQueryValidator = ajv.compile(accountsQueryValidation);
