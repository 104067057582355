import { shape, string, arrayOf } from 'prop-types';
import { SaplingUtil } from '@axiom/ui';

import {
  CandidateApi,
  getCandidate,
  updateCandidate,
} from '../../api/protected/candidates/candidate';
import { CandidateProfileIssuesApi } from '../../api/protected/candidates/candidate-profile-issues';
import { getProfileImageUrl } from '../../api/protected/candidates/candidate-profile-image';
import { CertificationShape } from '../../models/certification-model';
import RecordStateHelper from '../../lib/record-state-helper';
import SubmitStateHelper from '../../lib/submit-state-helper';
import { PreloadedPermissionsStore } from '../preloaded-permissions-store';
import { PreloadedFeatureFlagsStore } from '../preloaded-feature-flags-store';
import { SaplingApi } from '../../api/sapling';

/* eslint-disable import/no-cycle */
import { FormAvailabilityPreferencesStore } from './form-availability-preferences-store';
import { CandidateStore } from './candidate-store';
/* eslint-enable import/no-cycle */

export const CURRENT_CANDIDATE_NAMESPACE = 'CURRENT_CANDIDATE';

const stateGetCandidate = new RecordStateHelper(
  CURRENT_CANDIDATE_NAMESPACE,
  'FETCH'
);

const stateDeleteAccomplishment = new SubmitStateHelper(
  CURRENT_CANDIDATE_NAMESPACE,
  'DELETE_CERTIFICATE'
);

const stateDeleteEducationItem = new SubmitStateHelper(
  CURRENT_CANDIDATE_NAMESPACE,
  'DELETE_EDUCATION_ITEM'
);

const mutateCandidate = candidate => {
  /**
   * Construct full name prop
   */
  const name = [
    candidate.nickName && candidate.nickName.length
      ? candidate.nickName
      : candidate.firstName,
    candidate.lastName,
  ];

  if (candidate.middleName && candidate.middleName.length) {
    name.splice(1, 0, candidate.middleName);
  }

  candidate.fullName = name.join(' ');

  candidate.practicingYears = candidate?.practiceStartYear
    ? new Date().getFullYear() - candidate.practiceStartYear
    : null;

  /**
   * Add profileImageUrl to candidate if they have one
   */
  candidate.profileImageUrl =
    candidate.profileImageKey && candidate.profileImageName
      ? getProfileImageUrl(candidate.id)
      : null;

  return candidate;
};

export const CurrentCandidateStore = {
  getInitialState() {
    return {
      data: {
        record: {},
      },
    };
  },
  getDataShape() {
    return shape({
      data: shape({
        record: shape({
          id: string,
        }),
        certifications: arrayOf(CertificationShape),
      }),
    });
  },
  getReducers() {
    return {
      ...stateGetCandidate.generateReducers(),
      ...stateDeleteAccomplishment.generateReducers(),
      ...stateDeleteEducationItem.generateReducers(),
    };
  },
  actionFetchCandidate(candidateId, user, loadFeatureFlags = false) {
    return dispatch =>
      dispatch(
        stateGetCandidate.fetchRecord(
          Promise.all([
            getCandidate(candidateId),
            SaplingApi.createToken(),
          ]).then(([candidate, tokenWrapper]) => {
            FormAvailabilityPreferencesStore.load(candidate);
            CandidateStore.load(candidate);
            if (loadFeatureFlags) {
              // user is real (not undefined) here, see AppStore.initTalentAppState
              PreloadedFeatureFlagsStore.bootstrap({ user, candidate });
            }
            // here, user can be undefined, but we still want to respond
            // to candidate updates that trigger this fetch; user-related
            // permissions are not updated when user is undefined
            PreloadedPermissionsStore.load({ candidate, user });
            SaplingUtil.initialize(tokenWrapper.token, user?.id);
            CandidateApi.refreshCandidate(candidateId);
            CandidateProfileIssuesApi.refreshCandidateProfileIssues(
              candidateId
            );

            return {
              candidateId: candidate.id,
              record: mutateCandidate(candidate),
              certifications: candidate.certifications || [],
              barredLocations: candidate.barredLocations,
            };
          })
        )
      );
  },
  actionUpdateCandidate(candidateId, props) {
    return dispatch =>
      updateCandidate(candidateId, props).then(() => {
        dispatch(CurrentCandidateStore.actionFetchCandidate(candidateId));
      });
  },
  selectCandidateState(state) {
    return stateGetCandidate.select(state);
  },
  selectCandidate(state) {
    return stateGetCandidate.selectData(state);
  },
};
