import React, { SyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import debounce from 'lodash/debounce';
import { SchemaTypesUtil } from '@axiom/types';

import { FormSchemaUtil } from '../../../utils/form-schema-util';

import { invokeFormContext } from './useFormContext';
import { FormProps, FormPropsChildren } from './FormTypes';

export const FormConstruction = <FormValues,>({
  children,
  dirty,
  handleReset,
  handleSubmit,
  isSubmitting,
  name,
  schema,
  setTouched,
  setFieldTouched,
  setValues,
  submitCount,
  submitOnChange,
  values,
}: {
  children: FormProps<FormValues>['children'];
  dirty: FormPropsChildren<FormValues>['dirty'];
  handleReset: (e?: SyntheticEvent<unknown, Event>) => void;
  handleSubmit: (e?: SyntheticEvent<unknown, Event>) => void;
  isSubmitting: boolean;
  name: string;
  schema: FormProps<FormValues>['schema'];
  setTouched: FormPropsChildren<FormValues>['setTouched'];
  setFieldTouched: FormPropsChildren<FormValues>['setFieldTouched'];
  setValues: FormPropsChildren<FormValues>['setValues'];
  submitCount: number;
  submitOnChange: boolean;
  values: FormPropsChildren<FormValues>['values'];
}) => {
  const FormContext = invokeFormContext();
  const formik = useFormikContext();

  const formattedSchema = useMemo(
    () => SchemaTypesUtil.format(schema),
    [schema]
  );

  const debouncedSubmit = useCallback(
    debounce(() => formik.submitForm(), 500),
    [formik.submitForm]
  );

  useEffect(() => {
    if (submitOnChange && formik.values !== formik.initialValues) {
      debouncedSubmit();
    }
  }, [formik.values]);

  let formStatus = 'form-active';
  if (submitCount > 0 && !isSubmitting) {
    formStatus = 'form-submitted';
  } else if (isSubmitting) {
    formStatus = 'form-submitting';
  }

  return (
    <div data-test={name}>
      <FormContext.Provider
        value={{
          getSchemaProperty: (propName: string) => {
            return FormSchemaUtil.find(formattedSchema, propName);
          },
          fireReset: handleReset,
          fireSubmit: handleSubmit,
          values,
        }}
      >
        <div data-test={formStatus}>
          <form noValidate onSubmit={handleSubmit}>
            {children({
              dirty,
              fireReset: handleReset,
              fireSubmit: handleSubmit,
              setTouched,
              setFieldTouched,
              setValues,
              values,
            })}
          </form>
        </div>
      </FormContext.Provider>
    </div>
  );
};
