import React from 'react';
import { CandidateWorkFeedItem } from '@axiom/validation';
import {
  backgroundColorNames,
  Badge,
  Gutter,
  Layout,
  BadgeBackgroundType,
} from '@axiom/ui';
import { WorkFeedConst } from '@axiom/const';

import { WorkFeedUtil } from '../../utils/work-feed-util';

const { Phases } = WorkFeedConst;

const badgeColorMap: Record<string, BadgeBackgroundType> = {
  [Phases.New]: backgroundColorNames.orange,
  [Phases.Submitted]: backgroundColorNames.lightOrange,
  [Phases.Interviewing]: backgroundColorNames.lightOrange,
  [Phases.Closed]: backgroundColorNames.gray,
};

export const WorkFeedCardBadges = ({
  workFeedItem,
}: {
  workFeedItem: CandidateWorkFeedItem;
}) => (
  <Gutter bottom="16px">
    <Layout name="WORK_FEED_CARD_BADGES" horizontalGutter="8px">
      <Badge
        name="OPPORTUNITY_STAGE"
        background={badgeColorMap[workFeedItem.phase]}
      >
        {WorkFeedUtil.formatPhaseForDisplay(workFeedItem.phase)}
      </Badge>

      {workFeedItem.opportunity.directEligible && (
        <Badge
          name="OPPORTUNITY_DIRECT_ELIGIBLE"
          background={backgroundColorNames.sky}
        >
          Submit to Client
        </Badge>
      )}
    </Layout>
  </Gutter>
);
