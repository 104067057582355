import React from 'react';
import {
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
} from '@axiom/ui';
import { Candidate, Education } from '@axiom/validation';

import { CandidateApi } from '../../api/protected/candidates/candidate';
import { EducationApi } from '../../api/protected/education/education';
import { CandidateProfileIssuesApi } from '../../api/protected/candidates/candidate-profile-issues';

export const EducationDeleteModal = ({
  candidateId,
  educationId,
  onClose,
}: {
  candidateId: Candidate['id'];
  educationId: Education['id'];
  onClose: () => void;
}) => {
  return (
    <Modal name="EDUCATION_DELETE_MODAL">
      <ModalHeader name="EDUCATION_DELETE_MODAL_HEADER" onClose={onClose}>
        Delete This Education
      </ModalHeader>
      <ModalSection>
        Deleting this education removes it from your Axiom for Talent Profile
        and the bio that Axiom shares with prospective clients.
      </ModalSection>
      <ModalFooter>
        <Button variation="outline" name="CANCEL" onClick={onClose}>
          Cancel
        </Button>
        <Button
          name="SUBMIT"
          onClick={async () => {
            await EducationApi.deleteEducation(educationId);
            await CandidateApi.refreshCandidate(candidateId);
            await CandidateProfileIssuesApi.refreshCandidateProfileIssues(
              candidateId
            );
            return onClose();
          }}
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};
