import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Banner,
  CondensedLarge,
  Grid,
  GridRow,
  GridColumn,
  Form,
  FormLabel,
  DateUtil,
  Textarea,
  Gutter,
  Dropdown,
  TaxonomyUtil,
  Flashy,
  FormGroup,
  Checkbox,
  Layout,
} from '@axiom/ui';
import {
  Candidate,
  CandidateProfileIssue,
  Experience,
  ExperienceEditSchema,
  Taxonomy,
} from '@axiom/validation';

import { ExperienceApi } from '../../api/protected/experiences/experience';
import { CandidateExperienceApi } from '../../api/protected/candidates/candidate-experience';
import { CandidateProfileIssuesApi } from '../../api/protected/candidates/candidate-profile-issues';

import { ExperienceItemEditLayout } from './ExperienceItemEditLayout';

const formSchema = ExperienceEditSchema.pick({
  description: true,
  industryValue: true,
  industryIsOverridden: true,
  generalSkills: true,
});

export const ExperienceItemAxiomEdit = ({
  candidateId,
  experience,
  taxonomy,
  issues,
}: {
  candidateId: Candidate['id'];
  experience: Experience;
  taxonomy: Taxonomy;
  issues: CandidateProfileIssue[];
}) => {
  const navigate = useNavigate();
  const [hasSaved, setHasSaved] = useState(false);
  const { industries, generalSkills } = taxonomy;
  const generalSkillOptions =
    TaxonomyUtil.getGeneralSkillOptions(generalSkills);
  const industryOptions = TaxonomyUtil.getIndustryOptions(industries);
  return (
    <Form
      name="EXPERIENCE_ITEM_AXIOM_EDIT_FORM"
      schema={formSchema}
      initialValues={{
        ...experience,
      }}
      onSubmit={async formData => {
        const submitData = {
          candidateId,
          ...formData,
          ...(formData.industryValue && { industryIsOverridden: true }),
        };

        await ExperienceApi.updateExperience(
          experience.id,
          ExperienceEditSchema.partial().parse(submitData)
        );

        await CandidateExperienceApi.refreshExperiences(candidateId);
        await CandidateProfileIssuesApi.refreshCandidateProfileIssues(
          candidateId
        );
        setHasSaved(true);
      }}
    >
      {({ dirty, fireSubmit }) => (
        <ExperienceItemEditLayout
          dirty={dirty}
          experience={experience}
          hasSaved={hasSaved}
          issues={issues}
          onProfileNav={() => {
            navigate('../../profile');
          }}
          onSave={fireSubmit}
        >
          <Grid>
            <GridRow>
              <GridColumn largeScreenWidth={6} smallScreenWidth={12}>
                <FormLabel>Company Name</FormLabel>
                <CondensedLarge name="client">
                  {experience.client}
                </CondensedLarge>
                <Gutter bottom="8px" />
              </GridColumn>
              <GridColumn largeScreenWidth={6} smallScreenWidth={12}>
                <FormLabel>Duration</FormLabel>
                <CondensedLarge name="duration">
                  {DateUtil.displayDateRange(
                    experience.startDate,
                    experience.endDate
                  )}
                </CondensedLarge>
                <Gutter bottom="8px" />
              </GridColumn>
              <GridColumn largeScreenWidth={6} smallScreenWidth={12}>
                <FormLabel>Location</FormLabel>
                <CondensedLarge name="location">
                  {experience.opportunity.locationCityStateCountry}
                </CondensedLarge>
                <Gutter bottom="8px" />
              </GridColumn>
            </GridRow>
          </Grid>
          <Textarea
            name="description"
            label="Description of work"
            full
            enableSuggestionsFor="portal-ExperienceItemAxiomEdit-description"
          />
          <Gutter bottom="16px" />
          <FormLabel>Associated skills</FormLabel>
          <Gutter bottom="8px" />
          <Banner>
            <Flashy bold>Coming soon - new skills!</Flashy> We are developing a
            new skills system that is more accurate and will help clients to
            better understand your strengths and abilities.
          </Banner>
          <Gutter bottom="16px" />
          <FormGroup name="generalSkills" label="General Skills (optional)">
            <Layout horizontalGutter="8px" verticalGutter="8px" wrap>
              {generalSkillOptions.map(({ label, value }) => (
                <Checkbox
                  key={value}
                  name="generalSkills"
                  displayValue={label}
                  option={value}
                  mode="pill"
                />
              ))}
            </Layout>
          </FormGroup>
          <Gutter bottom="16px" />
          <Dropdown
            displayKey="label"
            name="industryValue"
            options={industryOptions}
            valueKey="value"
            label="Industry"
          />
        </ExperienceItemEditLayout>
      )}
    </Form>
  );
};
