import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { CustomSchemaTypes } from '@axiom/types';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { DateUtil } from '../../../utils/date-util';
import 'react-datepicker/dist/react-datepicker.css';

const DateInputWrapper = styled.div``;

export type RawDateInputValueType = string | number;

export const ALLOWED_DATE_TYPES = [
  CustomSchemaTypes.SchemaDate,
  CustomSchemaTypes.SchemaMonthYear,
  CustomSchemaTypes.SchemaTime,
  CustomSchemaTypes.SchemaTimestamp,
  CustomSchemaTypes.SchemaYear,
];

export type RawDateInputProps = {
  disabled?: boolean;
  id?: string;
  invalid?: boolean;
  name: string;
  onBlur?: (value?: RawDateInputValueType) => void;
  onChange?: (value?: RawDateInputValueType) => void;
  onFocus?: (value?: RawDateInputValueType) => void;
  placeholder?: string;
  type: string;
  value?: RawDateInputValueType;
};

export const RawDateInput = ({
  disabled = false,
  id,
  invalid = false,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  type,
  value,
}: RawDateInputProps) => {
  if (!ALLOWED_DATE_TYPES.includes(type)) {
    throw new Error(
      `Only allowed schema types for DateInput are: ${ALLOWED_DATE_TYPES.join(
        ', '
      )}; Schema type provided is ${type}`
    );
  }
  const typeSpecificProps = {
    [CustomSchemaTypes.SchemaDate]: {
      formatIn: (v?: RawDateInputValueType) => {
        return v ? moment(v).toDate() : null;
      },
      formatOut: (date?: Date | string) => {
        return DateUtil.formatAsDate(date);
      },
    },
    [CustomSchemaTypes.SchemaTimestamp]: {
      dateFormat: 'MMMM d, yyyy h:mm aa',
      showTimeSelect: true,
      timeCaption: 'Time',
      formatIn: (v?: RawDateInputValueType) => {
        return v ? moment(v).toDate() : null;
      },
      formatOut: (date?: Date | string) => {
        return DateUtil.formatAsTimestamp(date);
      },
    },

    [CustomSchemaTypes.SchemaTime]: {
      dateFormat: 'h:mm aa',
      showTimeSelect: true,
      showTimeSelectOnly: true,
      timeCaption: 'Time',
      formatIn: (v?: RawDateInputValueType) => {
        return v ? moment(v, DateUtil.getTimeFormat()).toDate() : null;
      },
      formatOut: (date?: Date | string) => {
        return DateUtil.formatAsTime(date);
      },
    },
    [CustomSchemaTypes.SchemaMonthYear]: {
      dateFormat: 'MM/yyyy',
      showMonthYearPicker: true,
      showFullMonthYearPicker: true,
      formatIn: (v?: RawDateInputValueType) => {
        return v ? moment(v).date(1).toDate() : null;
      },
      formatOut: (date?: Date | string) => {
        return DateUtil.formatAsDate(date);
      },
    },
    [CustomSchemaTypes.SchemaYear]: {
      dateFormat: 'yyyy',
      showYearPicker: true,
      formatIn: (v?: RawDateInputValueType) => {
        return v ? moment().year(+v).month(6).date(1).toDate() : null;
      },
      formatOut: (date?: Date | string) => {
        return +DateUtil.formatAsYear(date);
      },
    },
  } as unknown as {
    [key: string]: {
      formatIn: (v?: RawDateInputValueType) => Date | null;
      formatOut: (date?: Date | string) => RawDateInputValueType;
    };
  };

  const { formatIn, formatOut, ...componentProps } = typeSpecificProps[type];
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <DateInputWrapper
      className={AttrsHelper.formatClassname(
        'raw-dateinput',
        invalid && 'error-state',
        disabled && 'disabled-state'
      )}
      data-test={name}
    >
      <DatePicker
        className="calendar-input"
        disabled={disabled}
        id={id}
        onBlur={e => {
          onBlur(formatOut(e.target.value));
        }}
        onChange={date => {
          onChange(formatOut(date));
        }}
        onFocus={() => {
          onFocus();
        }}
        placeholderText={placeholder}
        selected={formatIn(value)}
        todayButton="Today"
        {...componentProps}
      />
    </DateInputWrapper>
  );
};
