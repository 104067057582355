import React, { FC } from 'react';
import {
  Banner,
  Button,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
} from '@axiom/ui';

interface PreferredWeeklyHoursWarningModalProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export const PreferredWeeklyHoursWarningModal: FC<
  PreferredWeeklyHoursWarningModalProps
> = ({ onConfirm, onCancel }) => (
  <Modal name="preferred-weekly-hours-warning-modal">
    <ModalHeader onClose={onCancel}>Max hours</ModalHeader>
    <ModalSection>
      <Banner type="critical">
        You may request availability of up to 80 hours, but only 60 hours per
        week can be reserved/full time work. The rest should be made up of
        variable work.
      </Banner>
    </ModalSection>
    <ModalFooter>
      <Button
        name="preferred-weekly-hours-warning-cancel"
        variation="outline"
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        name="preferred-weekly-hours-warning-confirm"
        variation="filled"
        onClick={onConfirm}
      >
        Confirm
      </Button>
    </ModalFooter>
  </Modal>
);
