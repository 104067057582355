import React from 'react';
import {
  Accordion,
  AccordionHeader,
  Accordions,
  AccordionSection,
  CondensedHeader,
  Gutter,
  ReadonlyTextarea,
} from '@axiom/ui';
import { Experience } from '@axiom/validation';

import { ExperienceDescriptionGuidelines } from './ExperienceDescriptionGuidelines';

export const ExperienceItemEditTips = ({
  experience,
}: {
  experience?: Experience;
}) => {
  return (
    <Accordions name="EXPERIENCE_ITEM_EDIT_TIPS">
      <Accordion name="DESCRIPTION_GUIDELINES">
        <AccordionHeader>
          <CondensedHeader>How to write a great description</CondensedHeader>
        </AccordionHeader>
        <AccordionSection>
          <ExperienceDescriptionGuidelines />
        </AccordionSection>
      </Accordion>
      {!!experience?.opportunity?.position?.description && (
        <Accordion
          name="ENGAGEMENT_DESCRIPTION"
          expanded={!experience.description}
        >
          <AccordionHeader>
            <CondensedHeader>Engagement description</CondensedHeader>
          </AccordionHeader>
          <AccordionSection name="ENGAGEMENT_DESCRIPTION_SECTION">
            <Gutter top="24px">
              <ReadonlyTextarea
                name="position_description"
                value={experience.opportunity.position.description}
              />
            </Gutter>
          </AccordionSection>
        </Accordion>
      )}
    </Accordions>
  );
};
