import React from 'react';

import { ExperiencesEdit } from '../components/Experiences/ExperiencesEdit';
import { TalentDataProvider } from '../providers/TalentDataProvider';
import { PageLayout } from '../layouts/PageLayout';

export const ExperienceEditPage = () => (
  <TalentDataProvider>
    <PageLayout showTalentLinks>
      <div data-test="EXPERIENCE_EDIT_PAGE">
        <ExperiencesEdit />
      </div>
    </PageLayout>
  </TalentDataProvider>
);
