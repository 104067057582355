import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  Banner,
  Button,
  DateUtil,
  Grid,
  GridColumn,
  Gutter,
  Layout,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
  SmallHeader,
  Visible,
} from '@axiom/ui';
import { CandidateProfileIssue, Experience } from '@axiom/validation';
import { CandidateProfileIssuesUtils } from '@axiom/utils';

import { ExperienceItemEditTips } from './ExperienceItemEditTips';
import { ExperienceMissingIndustryPrompt } from './ExperienceMissingIndustryPrompt';
import { ExperienceSavedMarker } from './ExperienceSavedMarker';

export type ExperienceItemEditLayoutProps = {
  children: React.ReactNode;
  dirty: boolean;
  experience?: Experience;
  hasSaved: boolean;
  issues: CandidateProfileIssue[];
  onProfileNav: () => void;
  onSave: () => void;
};
export const ExperienceItemEditLayout = ({
  children,
  dirty,
  experience = {},
  hasSaved,
  issues,
  onProfileNav,
  onSave,
}: ExperienceItemEditLayoutProps) => {
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const showSaved = !dirty && hasSaved;
  return (
    <>
      <Button
        name="EXPERIENCES_BACK_TO_PROFILE"
        pattern="secondary"
        variation="minimal"
        icon="arrow-left"
        iconPosition="left"
        onClick={event => {
          if (dirty) {
            event.preventDefault();
            setShowUnsavedChangesModal(true);
          } else {
            onProfileNav();
          }
        }}
      >
        Back to profile
      </Button>
      <Gutter bottom="24px" />
      <Layout
        stackableOn="mobile"
        position="space-between"
        verticalGutter="24px"
      >
        <SmallHeader name="EXPERIENCE_EDIT_HEADER">
          {!isEmpty(experience)
            ? [
                'Edit your experience',
                experience.client && `at ${experience.client}`,
                experience.startDate &&
                  `(${DateUtil.displayDateRange(
                    experience.startDate,
                    experience.endDate
                  )})`,
              ]
                .filter(Boolean)
                .join(' ')
            : 'Add an experience'}
        </SmallHeader>
        {showSaved && (
          <div>
            <ExperienceSavedMarker name="EXPERIENCE_SAVED_MARKER_TOP" />
          </div>
        )}
      </Layout>
      <Gutter bottom="24px" />
      {/* cannot use GridRow with reversed */}
      <Grid reversed="smallScreen vertically">
        <GridColumn smallScreenWidth={12} largeScreenWidth={7}>
          {children}
          <Gutter bottom="16px" />
          <Layout position="right middle" horizontalGutter="8px">
            {showSaved && (
              <div>
                <ExperienceSavedMarker name="EXPERIENCE_SAVED_MARKER_BOTTOM" />
              </div>
            )}
            <Button
              variation="filled"
              name="EXPERIENCE_EDIT_SAVE"
              onClick={() => onSave()}
            >
              Save
            </Button>
          </Layout>
        </GridColumn>
        <GridColumn smallScreenWidth={12} largeScreenWidth={5}>
          {CandidateProfileIssuesUtils.hasExperienceWithMissingIndustryIssue(
            issues,
            experience.id
          ) && (
            <Gutter bottom="16px">
              <Banner name="ADD_INDUSTRY_HINT_EDIT" type="info" impact="high">
                <ExperienceMissingIndustryPrompt />
              </Banner>
            </Gutter>
          )}
          <ExperienceItemEditTips experience={experience} />
          <Visible only="smallScreen">
            <Gutter bottom="24px" />
          </Visible>
        </GridColumn>
      </Grid>
      {showUnsavedChangesModal && (
        <Modal name="EXPERIENCE_EDIT_UNSAVED_CHANGES_MODAL">
          <ModalHeader onClose={() => setShowUnsavedChangesModal(false)}>
            You have unsaved changes
          </ModalHeader>
          <ModalSection>
            <Paragraph>
              If you go back to profile now, you will lose the changes you’ve
              made on this page.
            </Paragraph>
          </ModalSection>
          <ModalFooter>
            <Button
              name="EXPERIENCE_CONFIRM_BACK_TO_PROFILE"
              variation="outline"
              onClick={() => onProfileNav()}
            >
              Back to profile
            </Button>
            <Button
              name="EXPERIENCE_CONTINUE_EDITING"
              onClick={() => setShowUnsavedChangesModal(false)}
            >
              Continue editing
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
