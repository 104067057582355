import { arrayToConstMap, objectPick } from './utils';
import { CandidatesConst } from './candidate';
import { PositionsConst } from './position';
import {
  CountryCodesAbbreviationsType,
  CountryCodesConst,
} from './countryCodes';

const { CalculatedTalentTypes } = PositionsConst;
const { ProfileStatuses } = CandidatesConst;
const { CountryCodesAbbreviations } = CountryCodesConst;

const NonConformingCountryCodes = {
  GB: 'Great Britain',
} as const;

const NonConformingCountryCodesAbbreviations = {
  GB: 'GB',
} as const;

export type NonConformingCountryCodesAbbreviationsType =
  keyof typeof NonConformingCountryCodesAbbreviations;
export type NonConformingCountryCodesType =
  (typeof NonConformingCountryCodesAbbreviations)[NonConformingCountryCodesAbbreviationsType];

export type NonConformingOrConformingCountryCodesAbbreviationsType =
  | CountryCodesAbbreviationsType
  | NonConformingCountryCodesAbbreviationsType;

const NonConformingCountryCodesPicker = objectPick({
  // Normal Country Codes
  ...CountryCodesAbbreviations,
  // Non Conforming Country Codes
  ...NonConformingCountryCodesAbbreviations,
  // Overriding Country Codes
  [CountryCodesAbbreviations.UK]: NonConformingCountryCodesAbbreviations.GB,
}) as (
  a: NonConformingOrConformingCountryCodesAbbreviationsType[]
) => Record<NonConformingOrConformingCountryCodesAbbreviationsType, string>;

const StateCountryCodesPicker = objectPick({
  // Normal Country Codes
  ...CountryCodesAbbreviations,
  // Non Conforming Country Codes
  ...NonConformingCountryCodesAbbreviations,
  // Overriding Country Codes
  [CountryCodesAbbreviations.UK]: NonConformingCountryCodesAbbreviations.GB,
}) as (
  a: NonConformingOrConformingCountryCodesAbbreviationsType[]
) => Record<NonConformingOrConformingCountryCodesAbbreviationsType, string>;

const SupportedOccupationTypes = {
  lawyer: 'Lawyer',
  lawyerFlex: 'Lawyer-Flex',
  legalSupport: 'Legal Support',
} as const;

const BlockingConditions = [
  'oppDoesNotExist',
  'oppClosed',
  'noPositions',
  'notAllPositionsAreAcceptablyTyped',
  'positionDescriptionsAllEmpty',
  'matchesNotAvailableInCountry',
];

const BlockingConditionsMap = arrayToConstMap(BlockingConditions);

if (Object.keys(BlockingConditionsMap).some(k => k === 'undefined')) {
  throw new Error(
    `A key in BlockingConditionsMap is undefined in ml recommendations const (aka developer do bad-bad) ${Object.keys(
      BlockingConditionsMap
    )}`
  );
}

export const MLRecommendationsConst = {
  BlockingConditions,
  BlockingConditionsMap,
  SupportedOccupationTypes,

  DefaultProfileStatuses: [
    ProfileStatuses.Active,
    ProfileStatuses.Beach,
    ProfileStatuses.PendingActive,
    ProfileStatuses.PendingBeach,
    ProfileStatuses.Waitlist,
  ],

  CalculatedTalentTypeToOccupationTypes: {
    [CalculatedTalentTypes.Lawyer]: [
      SupportedOccupationTypes.lawyer,
      SupportedOccupationTypes.lawyerFlex,
    ],
    [CalculatedTalentTypes.NonLawyer]: [SupportedOccupationTypes.legalSupport],
  },

  NonConformingCountryCodes,
  NonConformingCountryCodesAbbreviations,

  SupportedAddressCountryCodeToCountryCodeAbbreviations:
    NonConformingCountryCodesPicker([
      CountryCodesAbbreviations.US,
      NonConformingCountryCodesAbbreviations.GB,
      CountryCodesAbbreviations.UK,
    ]),

  SupportedStateCountryCodeToStateCountryCodeAbbreviations:
    StateCountryCodesPicker([
      CountryCodesAbbreviations.US,
      NonConformingCountryCodesAbbreviations.GB,
      CountryCodesAbbreviations.UK,
    ]),
} as const;
