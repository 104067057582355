import { AreaOfStudy, Education } from '@axiom/validation';
import { Education as EducationConst } from '@axiom/const';

export const EducationUtil = {
  getAreasOfStudyOptions(areasOfStudy: AreaOfStudy[]) {
    return areasOfStudy
      .map(area => ({ label: area.name, value: area.id }))
      .sort((a, b) => (a.label > b.label ? 1 : -1));
  },

  getDegreeOptions(
    educations: Education[] = [],
    modifyEducation: Education = {}
  ) {
    return Object.values(EducationConst.DEGREES).reduce((acc, curr) => {
      // omit JD unless none exists or it's already in the form
      if (
        curr === EducationConst.DEGREES.JD &&
        modifyEducation.degree !== EducationConst.DEGREES.JD &&
        educations.some(i => i.degree === EducationConst.DEGREES.JD)
      ) {
        return acc;
      }

      acc.push({ label: curr, value: curr });
      return acc;
    }, []);
  },

  formatEducationDetails(education: Education) {
    return [
      education.degree,
      education.areaOfStudy?.name,
      education.yearAwarded &&
        `${
          education.yearAwarded > new Date().getFullYear()
            ? `expected completion ${education.yearAwarded}`
            : `${education.yearAwarded}`
        }`,
    ]
      .filter(Boolean)
      .join(', ');
  },
};
