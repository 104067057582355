import React, { useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardSection,
  CondensedLarge,
  Gutter,
  Paragraph,
  Progress,
  SmallHeader,
  useApiWithErrors,
  ApiError,
} from '@axiom/ui';
import { Candidate } from '@axiom/validation';
import { Link, useNavigate } from 'react-router-dom';
import { Plural } from '@lingui/macro';

import { EnvUtil } from '../../utils/env-util';
import { WorkFeedApi } from '../../api/protected/candidates/candidate-workfeed';

export const WorkFeedInterestedTracker = ({
  candidateId,
}: {
  candidateId: Candidate['id'];
}) => {
  const [interestedCountData] = useApiWithErrors(
    WorkFeedApi.readWorkFeedInterestedCount(candidateId)
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (interestedCountData instanceof ApiError) {
      const error = interestedCountData as ApiError;

      if (error.getStatusCode() === 403) {
        const failUrl = `../403`;
        navigate(failUrl, { replace: true });
        return;
      }
      throw error;
    }
  }, [interestedCountData]);

  if (interestedCountData instanceof ApiError) return null;

  const { interestedCount } = interestedCountData;
  const trackerBarColor = (count: number) => {
    return count <= 6 ? 'green' : 'red';
  };

  const countMax = EnvUtil.workFeedMaxInterestedLimit;
  const remainingOpportunities =
    interestedCount > countMax ? 0 : countMax - interestedCount;

  return (
    <Card name="WORK_FEED_INTERESTED_COUNT">
      <CardHeader>
        <SmallHeader name="expression-tracker-info">
          Opportunity Interest Counter
        </SmallHeader>
      </CardHeader>
      <CardSection>
        <Progress
          currentStep={interestedCount}
          totalSteps={countMax}
          color={trackerBarColor(interestedCount)}
          name="INTERESTED_PROGRESS"
        />
        <CondensedLarge name="REMAINING_OPPORTUNITIES">
          You can express interest in <strong>{remainingOpportunities}</strong>{' '}
          more{' '}
          <Plural
            value={remainingOpportunities}
            one="opportunity."
            other="opportunities."
          />
        </CondensedLarge>
        <Gutter top="24px" />
        <Paragraph name="OPPORTUNITY_INFO">
          Axiom considers you for up to {countMax} opportunities at a time. To
          review roles you’re currently interested in, check the{' '}
          <Link
            data-test="INTERESTED_TAB_LINK"
            to="../../opportunities/interested"
          >
            Interested tab.
          </Link>
        </Paragraph>
      </CardSection>
    </Card>
  );
};
