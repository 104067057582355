import React from 'react';
import styled from 'styled-components';
import { Icon, Layout, Paragraph } from '@axiom/ui';

const GreenAndIconSized = styled.div`
  color: ${({ theme }) => theme.colors.dataVizGreen};
  height: 18px;
`;
export const ExperienceSavedMarker: React.FC<{ name: string }> = ({ name }) => (
  <Layout position="middle right" horizontalGutter="8px" name={name}>
    <Paragraph>Saved</Paragraph>
    <GreenAndIconSized>
      <Icon name="circle-check-filled" />
    </GreenAndIconSized>
  </Layout>
);
