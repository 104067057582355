import React from 'react';

import { IconButton } from '../Button/IconButton';
import { CondensedLarge } from '../../content/CondensedLarge/CondensedLarge';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

export interface PillProps {
  className?: string;
  children: React.ReactNode;
  name?: string;
  onDismiss?: () => void;
  pattern?: 'primary' | 'secondary';
}

export const Pill = ({
  className,
  children,
  name,
  onDismiss,
  pattern = 'primary',
}: PillProps) => {
  return (
    <div
      className={AttrsHelper.formatClassname(
        'pill',
        className,
        `pattern-${pattern}`
      )}
      data-test={name}
    >
      <CondensedLarge className="pill-content">{children}</CondensedLarge>
      {onDismiss && (
        <IconButton
          name={`${name}-dismiss-pill`}
          icon="x-mark"
          pattern="secondary"
          variation="minimal"
          onClick={onDismiss}
          className="fixed-dimension-inline"
        />
      )}
    </div>
  );
};
