import React, { useEffect } from 'react';
import { FormFieldProps } from 'semantic-ui-react';
import { GdprApprovedFields, GdprApprovedFieldType } from '@axiom/const';

import { DesignSysEnvUtil } from '../../../utils/design-sys-env-util';
import { FormField } from '../FormField/FormField';

import {
  RawTextarea,
  RawTextareaProps,
  RawTextareaValueType,
} from './RawTextarea';

export type TextareaProps = {
  description?: string;
  disabled?: RawTextareaProps['disabled'];
  enableSuggestionsFor?: GdprApprovedFieldType;
  full?: boolean;
  id?: RawTextareaProps['id'];
  label?: React.ReactNode;
  name: RawTextareaProps['name'];
  onBlur?: () => void;
  onChange?: (value?: RawTextareaValueType) => void;
  onFocus?: () => void;
  placeholder?: RawTextareaProps['placeholder'];
  readonly?: boolean;
  Tooltip?: React.ReactNode;
};

export const Textarea = ({
  description,
  disabled,
  enableSuggestionsFor,
  full,
  id,
  label,
  name,
  onBlur = () => {},
  onChange = () => {},
  onFocus = () => {},
  placeholder,
  readonly,
  Tooltip,
}: TextareaProps) => {
  id = id || name;

  // Requires Sapling to be initialized in target application (SaplingUtil.initialize())
  useEffect(() => {
    if (DesignSysEnvUtil.enableSuggestions && enableSuggestionsFor) {
      if (!GdprApprovedFields.some(field => field === enableSuggestionsFor)) {
        throw new Error(
          `Suggestions are not allowed for ${enableSuggestionsFor}`
        );
      }

      const SaplingModule = import('@saplingai/sapling-js/observer');
      const editor: HTMLElement = document
        .getElementById(id)
        .querySelector('.public-DraftEditor-content');

      SaplingModule.then(({ Sapling }) => {
        try {
          Sapling.observe(editor);
        } catch (e) {
          // This is a good console log
          // eslint-disable-next-line no-console
          console.error(
            `There was an error with Sapling. Did you initialize within the application? Error: ${e}`
          );
        }
      });

      return () => {
        SaplingModule.then(({ Sapling }) => {
          Sapling.unobserve(editor);
        });
      };
    }

    return () => {};
  }, [enableSuggestionsFor]);

  return (
    <FormField
      {...{
        description,
        disabled,
        id,
        name,
        label,
        showCharactersRemaining: true,
        Tooltip,
      }}
      renderField={({
        value,
        onBlur: formOnBlur,
        setFieldTouched,
        setFieldValue,
        invalid,
      }: {
        value: RawTextareaProps['value'];
      } & FormFieldProps) => (
        <RawTextarea
          disabled={disabled}
          full={full}
          id={id}
          invalid={invalid}
          name={name}
          onBlur={() => {
            onBlur();
            formOnBlur(name);
            setFieldTouched(name, true);
          }}
          onChange={v => {
            onChange(v);
            setFieldValue(name, v);
          }}
          onFocus={() => {
            onFocus();
          }}
          placeholder={placeholder}
          readonly={readonly}
          value={value}
        />
      )}
    />
  );
};
