import React from 'react';
import { useParams } from 'react-router-dom';
import { useApi } from '@axiom/ui';

import { UserApi } from '../../api/protected/user/user';
import { CandidateUtil } from '../../utils/candidate-util';
import { TaxonomyApi } from '../../api/protected/taxonomy/taxonomy';
import { CandidateExperienceApi } from '../../api/protected/candidates/candidate-experience';
import { CandidateProfileIssuesApi } from '../../api/protected/candidates/candidate-profile-issues';

import { ExperienceItemNonAxiomEdit } from './ExperienceItemNonAxiomEdit';
import { ExperienceItemAxiomEdit } from './ExperienceItemAxiomEdit';

export const ExperiencesEdit = () => {
  const { experienceId, candidateId: urlCandidateId } = useParams();
  const [{ data: user }, { data: taxonomy }] = useApi(
    UserApi.readUser(),
    TaxonomyApi.readTaxonomy()
  );
  const candidateId = CandidateUtil.reconcileUserCandidateId(
    user,
    urlCandidateId
  );
  const [{ data: issues }, { data: experiences } = { data: [] }] = useApi(
    CandidateProfileIssuesApi.readCandidateProfileIssues(candidateId),
    experienceId && CandidateExperienceApi.readExperiences(candidateId)
  );
  const experience = experiences.find(exp => exp.id === experienceId);
  return experience?.isAxiom ? (
    <ExperienceItemAxiomEdit
      candidateId={candidateId}
      experience={experience}
      taxonomy={taxonomy}
      issues={issues}
    />
  ) : (
    <ExperienceItemNonAxiomEdit
      candidateId={candidateId}
      experience={experience}
      taxonomy={taxonomy}
      issues={issues}
    />
  );
};
