import React, { SyntheticEvent } from 'react';

import { Layout } from '../../layout/Layout/Layout';
import { LayoutItem } from '../../layout/Layout/LayoutItem';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

export interface RawCheckboxPillProps {
  children: React.ReactNode;
  disabled?: boolean;
  onClick: (e: SyntheticEvent) => void;
  selected?: boolean;
  name: string;
}

export const RawCheckboxPill = ({
  children,
  disabled = false,
  onClick,
  selected = false,
  name,
}: RawCheckboxPillProps) => {
  return (
    <div
      className={AttrsHelper.formatClassname(
        'checkbox-pill',
        disabled && 'disabled',
        selected ? 'selected' : 'unselected'
      )}
      data-test={name}
      onClick={!disabled ? onClick : undefined}
    >
      <Layout>
        <LayoutItem className="checkbox-pill-content" fluid hug>
          {children}
        </LayoutItem>
      </Layout>
    </div>
  );
};
