import React, { useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { CardTitle } from 'reactstrap';
import {
  Button,
  CardLayout,
  DSCandidate,
  DSNoCandidate,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  Header4,
  Visible,
  PDFExport,
  SubmissionUtil,
} from '@axiom/ui';

import QuickLinks from '../QuickLinks/QuickLinks';
import QuickLinksListItems from '../QuickLinksListItems/QuickLinksListItems';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { DigitalSubmissionStore } from '../../stores/digital-submission-store';
import { DateUtil } from '../../utils/date-util';
import { generateBenchUrl } from '../../utils/link-util';

import {
  DSVCardWrapper,
  DSVDateWrapper,
  DSVPositionName,
  DSVQuickLinksWrapper,
} from './DigitalSubmissionViewStyles';

const DigitalSubmissionViewComponent = ({ digitalSubmissionState }) => {
  const pdfRoot = useRef(null);
  const quickLinksRef = useRef(null);
  const [qlMaxWidth, setQlMaxWidth] = useState(null);

  useLayoutEffect(() => {
    function updateWidth() {
      if (quickLinksRef?.current?.clientWidth) {
        setQlMaxWidth(`${quickLinksRef.current.clientWidth}px`);
      }
    }

    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  return (
    <StoreStateTreatment
      storeState={digitalSubmissionState}
      renderLoadedView={({ data: submission }) => {
        const hasCandidates = submission.candidateOpportunities.length > 0;

        const positions = submission.candidateOpportunities.reduce((pos, c) => {
          if (c.position) {
            const position = pos.find(p => p.id === c.position.id);
            const candidate = {
              ...c,
              candidate: {
                ...c.candidate,
                displayBillingRate: c.displayBillingRate,
                proposedHourlyRate: c.proposedHourlyRate,
                // legacy position stuff
                position: { billingHoursPerDay: c.position.billingHoursPerDay },
                submissionDescription:
                  c.submissionDescription || c.candidate.publicCandidateSummary,
              },
            };
            if (position) {
              position.candidates.push(candidate);
            } else {
              pos.push({
                id: c.position.id,
                name: c.position.name,
                candidates: [candidate],
              });
            }
          }

          return pos;
        }, []);

        const constructQuickLinks = positionCandidates =>
          positionCandidates.map(({ candidate }) => ({
            text: candidate.calculatedDisplayName,
            sectionName: candidate.id,
          }));

        const headerText = (
          <>
            <Gutter bottom="0.5rem">
              <div>Curated Recommendations</div>
            </Gutter>
            <DSVDateWrapper>
              Prepared On{' '}
              {DateUtil.displayLongMonthDayYear(
                submission.submissionLastUpdatedAt
              )}
            </DSVDateWrapper>
          </>
        );

        return (
          <DSVCardWrapper>
            <CardLayout
              header={
                <CardTitle className="bg-secondary text-white main-card-title">
                  <Header4 as="span">Axiom Submissions</Header4>
                  {hasCandidates > 0 && (
                    <div className="float-right">
                      <Visible only="largeScreen" as="span">
                        <Button
                          name="DOWNLOAD_PDF_BUTTON"
                          onClick={() => pdfRoot.current.save()}
                        >
                          Download
                        </Button>
                        <Gutter left="1rem" as="span">
                          <Button
                            name="VIEW_BENCH_BUTTON"
                            toTab={generateBenchUrl(submission?.accountId)}
                          >
                            View Bench
                          </Button>
                        </Gutter>
                      </Visible>
                      {submission.ownerUser && (
                        <Gutter left="1rem" as="span">
                          <Button
                            // For safety we are url encoding the subject
                            to={`mailto:${
                              submission.ownerUser.email
                            }?subject=${encodeURI(
                              `Axiom Submission - ${submission.accountName}`
                            )}`}
                            name="Reply_button"
                          >
                            Reply
                          </Button>
                        </Gutter>
                      )}
                    </div>
                  )}
                </CardTitle>
              }
              body={
                <Grid>
                  <GridRow>
                    {hasCandidates && (
                      <GridColumn only="largeScreen" largeScreenWidth={3}>
                        <div ref={quickLinksRef}>
                          {positions.length === 1 && (
                            <QuickLinks
                              headerText={headerText}
                              fixedTopAmount="185px"
                              floatingTopAmount="35px"
                              floatingHeight="calc(100vh - 50px)"
                              maxWidth={qlMaxWidth}
                              renderListItems={
                                <QuickLinksListItems
                                  links={constructQuickLinks(
                                    positions[0].candidates
                                  )}
                                />
                              }
                            />
                          )}
                          {positions.length > 1 && (
                            <QuickLinks
                              headerText={headerText}
                              fixedTopAmount="185px"
                              floatingTopAmount="35px"
                              floatingHeight="calc(100vh - 50px)"
                              maxWidth={qlMaxWidth}
                              renderListItems={positions.map(
                                (position, index) => (
                                  <React.Fragment key={`${position.id}`}>
                                    <DSVPositionName>
                                      {`${
                                        position.name && position.name.length
                                          ? position.name
                                          : `Position ${index + 1}`
                                      }`}
                                    </DSVPositionName>
                                    <DSVQuickLinksWrapper>
                                      <QuickLinksListItems
                                        links={constructQuickLinks(
                                          position.candidates
                                        )}
                                      />
                                    </DSVQuickLinksWrapper>
                                  </React.Fragment>
                                )
                              )}
                            />
                          )}
                        </div>
                      </GridColumn>
                    )}
                    <GridColumn
                      smallScreenWidth={12}
                      largeScreenWidth={hasCandidates ? 9 : 12}
                    >
                      <Visible only="smallScreen">
                        <Gutter bottom="1rem">
                          <Header4>
                            {hasCandidates && 'Curated Talent Recommendations'}
                          </Header4>
                          <hr />
                        </Gutter>
                      </Visible>
                      {hasCandidates ? (
                        <PDFExport
                          fileName={SubmissionUtil.getSubmissionFileName(
                            submission
                          )}
                          ref={pdfRoot}
                        >
                          {positions.reduce((temps, p, pi) => {
                            return temps.concat(
                              p.candidates.map(({ candidate }, ci) => (
                                <DSCandidate
                                  candidate={candidate}
                                  experiences={candidate.experiences}
                                  currency={submission.currency}
                                  key={candidate.id}
                                  pageBreak={ci + pi > 0}
                                />
                              ))
                            );
                          }, [])}
                        </PDFExport>
                      ) : (
                        <DSNoCandidate />
                      )}
                    </GridColumn>
                  </GridRow>
                </Grid>
              }
            />
          </DSVCardWrapper>
        );
      }}
    />
  );
};

DigitalSubmissionViewComponent.propTypes = {
  digitalSubmissionState: DigitalSubmissionStore.getDataShape().isRequired,
};

DigitalSubmissionViewComponent.defaultProps = {};

export const DigitalSubmissionView = connect(state => ({
  digitalSubmissionState: DigitalSubmissionStore.select(state),
}))(DigitalSubmissionViewComponent);
