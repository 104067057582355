import { Api } from '@axiom/ui';
import { LawSchoolSearch } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class LawSchoolsApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiPortalUrl,
    });
  }

  readLawSchools(search?: string, page = 1) {
    return super.read<LawSchoolSearch>({
      endpoint: `/lawSchools`,
      method: 'GET',
      query: { search, page },
    });
  }
}

export const LawSchoolApi = new LawSchoolsApiClass();
