import { Api } from '@axiom/ui';
import { Candidate, Education, EducationEdit } from '@axiom/validation';

import { EnvUtil } from '../../../utils/env-util';

class CandidateDegreesApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.clientApiBase,
      cookieDomain: EnvUtil.cookieDomain,
    });
  }

  createEducation(id: Candidate['id'], education: EducationEdit) {
    return super.write<{ data: Education }>({
      endpoint: `/candidates/${id}/degrees`,
      body: education,
      method: 'POST',
    });
  }
}

export const CandidateDegreesApi = new CandidateDegreesApiClass();
