import memoize from 'lodash/memoize';
import moment from 'moment-timezone';
import { NO_TIMEZONE, TIMEZONES } from '@axiom/const';
import { TimeZone, TimeZoneIana, TimeZoneOffset } from '@axiom/validation';

type DropdownType = { label: string; value: TimeZone['id'] };

const allIanas = moment.tz.names();
type TimeZoneIanaAll = (typeof allIanas)[number];

export const CalendarTimeZoneUtil = {
  getDisplayZone: (timezone: TimeZone): DropdownType['label'] => {
    const nowWithOffset = moment(new Date()).tz(timezone.iana).format('h:mm a');
    return `${timezone.abbr} - ${timezone.name} (${nowWithOffset})`;
  },
  isDaylightSavings: (date: Date = new Date()) => {
    return moment(date).isDST();
  },
  getZonesFromOffset: (offset: TimeZoneOffset) => {
    return TIMEZONES.filter((tz: TimeZone) => tz.offset === offset);
  },
  getZonesFromIana: (iana: TimeZoneIanaAll) => {
    return TIMEZONES.filter((tz: TimeZone) => tz.iana === iana) || [];
  },

  getZoneFromId: memoize((id: TimeZone['id']): TimeZone => {
    return TIMEZONES.filter((tz: TimeZone) => tz.id === id)[0] || NO_TIMEZONE;
  }),

  getZoneFromMomentZone: (momentZone: moment.Moment) => {
    const zoneName = momentZone.zoneName();

    return TIMEZONES.filter((tz: TimeZone) => tz.momentName === zoneName)[0];
  },

  getDropDownOptions: memoize((): DropdownType[] => {
    const used = new Set<TimeZoneIana>();

    return TIMEZONES.reduce((data: DropdownType[], zone: TimeZone) => {
      const mZone = moment.tz(new Date(), zone.iana);

      if (!used.has(zone.iana) && zone.momentName === mZone.zoneName()) {
        data.push({
          label: CalendarTimeZoneUtil.getDisplayZone(zone),
          value: zone.id,
        });

        used.add(zone.iana);
      }

      return data;
    }, [] as DropdownType[]);
  }),
  getTimeZone: (talentIana?: TimeZoneIanaAll) => {
    let foundZone: TimeZone = NO_TIMEZONE;

    /**
     * If talentIana is not a valid IANA in moment.tz.names()
     * moment will throw an error.
     * Handling it gracefully here with this try/catch
     * */
    try {
      const zones = CalendarTimeZoneUtil.getZonesFromIana(talentIana);

      if (zones.length === 1) {
        [foundZone] = zones;
      } else if (zones.length > 1) {
        const mName = moment
          .tz(new Date(), talentIana || moment.tz.guess())
          .zoneName();
        [foundZone] = zones.filter(z => z.momentName === mName);
      } else {
        foundZone =
          CalendarTimeZoneUtil.getZoneFromMomentZone(
            moment.tz(new Date(), talentIana || moment.tz.guess())
          ) || NO_TIMEZONE;
      }
    } catch (e) {
      return NO_TIMEZONE;
    }
    return foundZone;
  },
};
