import React from 'react';

import { ViewportName } from '../../../configs/responsive-config';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { colors, shadows } from '../../../theme/axiom-theme';

export const horizontalPositions = [
  'left',
  'center',
  'right',
  'space-between',
  'space-around',
] as const;

export const verticalPositions = [
  'top',
  'middle',
  'bottom',
  'stretch',
] as const;

export type LayoutPositionType =
  | 'left'
  | 'center'
  | 'right'
  | 'space-between'
  | 'space-around'
  | 'top'
  | 'middle'
  | 'bottom'
  | 'stretch'
  | `left top`
  | 'left middle'
  | 'left bottom'
  | 'left stretch'
  | 'center top'
  | 'center middle'
  | 'center bottom'
  | 'center stretch'
  | 'right top'
  | 'right middle'
  | 'right bottom'
  | 'right stretch'
  | 'space-between top'
  | 'space-between middle'
  | 'space-between bottom'
  | 'space-between stretch'
  | 'space-around top'
  | 'space-around middle'
  | 'space-around bottom'
  | 'space-around stretch'
  | 'top left'
  | 'top center'
  | 'top right'
  | 'top space-between'
  | 'top space-around'
  | 'middle left'
  | 'middle center'
  | 'middle right'
  | 'middle space-between'
  | 'middle space-around'
  | 'bottom left'
  | 'bottom center'
  | 'bottom right'
  | 'bottom space-between'
  | 'bottom space-around'
  | 'stretch left'
  | 'stretch center'
  | 'stretch right'
  | 'stretch space-between'
  | 'stretch space-around';

export const Layout = ({
  background,
  border,
  borderLeft,
  borderRight,
  borderTop,
  borderBottom,
  borderRadius,
  boxShadow,
  className,
  children,
  direction = 'horizontal',
  horizontalGutter,
  inline,
  name,
  position,
  reversibleOn,
  stackableOn,
  verticalGutter,
  wrap,
  stretched,
}: {
  background?: keyof typeof colors | CssBackgroundImage;
  border?: string; // TODO APCORE-1551 Make this work: `${string}px ${string} ${keyof typeof colors}`
  borderLeft?: string;
  borderRight?: string;
  borderTop?: string;
  borderBottom?: string;
  borderRadius?: string;
  boxShadow?: keyof typeof shadows;
  className?: string;
  children?: React.ReactNode;
  direction?: 'horizontal' | 'vertical';
  horizontalGutter?: string;
  inline?: boolean;
  name?: string;
  position?: LayoutPositionType;
  reversibleOn?: string | ViewportName;
  stackableOn?: string | ViewportName;
  verticalGutter?: string;
  wrap?: boolean;
  stretched?: boolean;
}) => {
  const [positionHorizontal, positionVertical] = position
    ? position.split(' ').reduce(
        (acc, value) => {
          if (
            horizontalPositions.includes(
              value as (typeof horizontalPositions)[number]
            )
          ) {
            acc[0] = value;
          }
          if (
            verticalPositions.includes(
              value as (typeof verticalPositions)[number]
            )
          ) {
            acc[1] = value;
          }
          return acc;
        },
        [null, null]
      )
    : [];
  return (
    <div
      className={AttrsHelper.formatClassname(
        'layout-grid',
        `direction-${direction}`,
        inline && 'inline-flex',
        positionHorizontal && `horizontal-${positionHorizontal}`,
        positionVertical && `vertical-${positionVertical}`,
        reversibleOn &&
          reversibleOn
            .split(' ')
            .map(breakpoint => `reversible-${direction}-${breakpoint}`)
            .join(' '),
        stackableOn &&
          stackableOn
            .split(' ')
            .map(breakpoint => `stackable-${breakpoint}`)
            .join(' '),

        wrap && 'wrap-content',
        stretched && 'layout-stretched',
        className
      )}
      data-test={name}
      style={{
        backgroundColor: typeof background === 'string' && colors[background],
        ...(background && typeof background === 'object'
          ? {
              backgroundImage: `url("${background.image}")`,
              backgroundAttachment: background.attachment,
              backgroundClip: background.clip,
              backgroundColor: background.color,
              backgroundPosition: background.position || 'center right',
              backgroundRepeat: background.repeat || 'no-repeat',
            }
          : {}),
        border: AttrsHelper.replaceColorNameWithHex(border),
        borderLeft: AttrsHelper.replaceColorNameWithHex(borderLeft),
        borderRight: AttrsHelper.replaceColorNameWithHex(borderRight),
        borderTop: AttrsHelper.replaceColorNameWithHex(borderTop),
        borderBottom: AttrsHelper.replaceColorNameWithHex(borderBottom),
        boxShadow: boxShadow && shadows[boxShadow],
        borderRadius,
        rowGap: verticalGutter,
        columnGap: horizontalGutter,
      }}
    >
      {children}
    </div>
  );
};
