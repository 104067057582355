import React from 'react';
import { ProfileCompletionConst } from '@axiom/const';

import { ProfileCompletionBanner } from '../ProfileCompletionBanner/ProfileCompletionBanner';

export const EducationBannerPrompt = ({
  onOpenEdit = null,
}: {
  onOpenEdit?: () => void;
}) => {
  return (
    <ProfileCompletionBanner
      title={
        ProfileCompletionConst.ProfileCompletionEducationBanner.missingTitle
      }
      description={
        ProfileCompletionConst.ProfileCompletionEducationBanner.missingContent
      }
      estimatedTime="5 min"
      onOpenEdit={onOpenEdit}
      name="EDUCATION_BANNER_PROMPT"
    />
  );
};
