import React, { SyntheticEvent } from 'react';

import { LayoutItem } from '../Layout/LayoutItem';
import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { Layout } from '../Layout/Layout';

export type TileProps = {
  Badge?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  name?: string;
  onClick?: (e: SyntheticEvent) => void;
  selected?: boolean;
};
export const Tile = ({
  Badge,
  children,
  className,
  disabled,
  name,
  onClick,
  selected,
}: TileProps) => {
  return (
    <div
      className={AttrsHelper.formatClassname(
        'tile',
        disabled && 'disabled',
        onClick && 'selectable',
        selected && 'selected',
        className
      )}
      data-test={name}
      onClick={!disabled ? onClick : undefined}
    >
      <Layout
        reversibleOn="smallScreen"
        stackableOn="smallScreen"
        verticalGutter="8px"
      >
        <LayoutItem className="tile-label" fluid hug>
          {children}
        </LayoutItem>
        {Badge && <div className="header-badge">{Badge}</div>}
      </Layout>
    </div>
  );
};
